import "../components/WhitepaperSytles.css";

function Whitepaper1() {
  return (
    <div className="about-container obj-width">
      <h1>White Paper</h1>
      <h2>Abstract:</h2>
      <p>
      The Philosoraptor Token isn't just another token; it's a galaxy-brained leap into the cosmos, where memes and money collide in the most epic way possible. This memetic white paper breaks down how we're flipping the script on, with 85% of tokens supercharged for liquidity, all while dancing with memes and community magic.
      </p>
      <h2>1. Introduction:</h2>

      <p>
      Hold onto your keyboards, because the Philosoraptor Token is dropping some meme-powered wisdom right here. We're talking memes that launch rockets, memes that forge alliances, and memes that mint money. Buckle up for a wild ride through transparency, memes, and dreams!
      </p>
      <h2>2. Allocation Strategy:</h2>
      <p>
      Prepare to be mind-blown. 85% of our token army is on a mission to fuel the liquidity pool like there's no tomorrow. That means when you need to swap tokens faster than you can say  moon," we've got your back. But wait, there's more! To prove we mean business, we're throwing our LP tokens into the incinerator - a true phoenix moment that shouts "integrity" in meme language.
      </p>
      <h2>3. Community Involvement and Renouncement:</h2>
      <p>
      We're all about community vibes, and we're putting our tokens where our mouths are. Say goodbye to the old-school control freaks - we're renouncing the contract and passing the torch to our meme-savvy community. We trust you to protect the galaxy from FUD and moonwalk us to stardom. Because when memes unite, anything's possible!
      </p>
      <h2>
      4. Community Rewards:
      </h2>
      <p>
      Hold onto your memes, because we're dedicating a whopping 5% of our token supply to celebrate YOU. Long-term holders, meme maestros, and connoisseurs, get ready for airdrops that rain rewards like confetti. But here's the twist: 5% is powering meme-fueled partnerships that'll take us to the moon and beyond, while another 5% is turbocharging our journey to centralized exchange (CEX) listings. These aren't just tokens; they're tickets to the meme-powered partnerships and exchanges that'll shape the cosmos.
      </p>
      <h2>
      5. Innovation and Community Synergy:
      </h2>
      <p>
      The Philosoraptor Token is where innovation meets meme culture in an epic collision. This ain't your grandma's; it's a rocket-powered journey where memes inspire partnerships that blast us through the stars. With our dynamic meme tribe, we're building bridges, shattering ceilings, and creating the galaxy's most epic liquidity pools.
      </p>
      <h2>
      6. Staking:
      </h2>
      <p>
      Get ready to stake your tokens and ride the staking wave with us! The Philosoraptor Token is here to memeify finance, launch rockets, and make dreams reality. But remember, this isn't financial advice - it's meme-driven magic. Join our meme-fueled revolution and let's create the future, one staking meme at a time.
      </p>
      <h2>
      7. Conclusion:
      </h2>
      <p>
      Buckle up, meme warriors! The Philosoraptor Token is here to memeify finance, launch rockets, and make dreams reality. But remember, this isn't financial advice - it's meme-driven magic. Join our meme-fueled revolution and let's create the future, one meme at a time.
      </p>
      
      <h2>
      Disclaimer:
      </h2>
      <p>
      Hey there, meme explorers! This memelicious white paper is all about the laughs and the facts. But hold up - don't let our memes be your financial guides. Remember, do your own research before diving into the meme-laden universe of. While we're shooting for the moon, things might have shuffled around since this meme extravaganza hit the virtual presses. Keep memeing responsibly and stay interstellar! 🚀🪐
      </p>
    </div>
  );
}

export default Whitepaper1;
