import Navbar from "../components/Navbar";
import HomeHero from "../components/Home";
import AboutImg from "../assets/raptormeme.webp";
import Footer from "../components/Footer";




const Generator = () => {
  

  return (
    <div>
      <Navbar />
      <HomeHero
        cName="hero-mid"
        heroImg={AboutImg}
        title="Service"
        btnClass="hide"
      />
      <div>
        <div className="main-content1">
          <h1>Philosoraptor Meme Generator</h1>
          <p><strong>Click on an image below to get started.</strong> </p>
          <p>Pro Tip: Effortlessly rearrange the text using your mouse or touchscreen.</p>
          <iframe src="https://pigstand.github.io/philosoraptorgen" title="generator" height={600} ></iframe>
        </div>

        </div>
    
      <Footer />
    </div>
  );
};

export default Generator;
