import React from "react";
import Toke from "../assets/token1.webp";
import DestinationData from "./TokenomicsData";

const Tokenomics = () => {
  return (
    <>
      <div className="destination obj-width">
        <h1>Tokenomics</h1>
        <p></p>
        <DestinationData
          className="first-des"
          heading="Token Supply"
          heading2="420,690,000,000,000"
          text="A substantial 50% of the token allocation is dedicated to enhancing circulating supply, ensuring the token's stability, and promoting widespread accessibility. This commitment to transparency and security is exemplified through the burning of LP tokens, showcasing our unwavering dedication to maintaining project integrity. To bolster token scarcity, we plan to burn an additional 35% of tokens, solidifying our commitment to sustainable growth. Simultaneously, our belief in community involvement shines through as we renounce the contract, placing trust in our vigilant community members. The remaining 15% of token supply, carefully held, will serve as rewards for our community, including airdrops for long-term holders. These incentives will foster strategic partnerships, propelling the token toward centralized listings, robust bridges, and enduring liquidity pools. This distribution strategy exemplifies our forward-thinking approach, striking a harmonious balance between innovation and community incentives, in alignment with our dynamic project and enthusiastic community.

          In addition to these distribution strategies, we're thrilled to introduce a staking program empowering our community to actively engage in project growth and governance. Through token staking, our community members can earn attractive rewards while contributing to ecosystem security and stability. This innovative staking feature seamlessly aligns with our commitment to community involvement, strengthening our project's foundation for sustainable growth. Stay tuned for more details on our staking program, offering you the opportunity to actively shape the future of our project."
          img1={Toke}
          
        />

        
      </div>
    </>
  );
};

export default Tokenomics;

