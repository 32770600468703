import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./NavbarStyles.css";


function Navbar() {
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(!clicked);
  };

  // Define your menu items for desktop and mobile screens
  const desktopMenuItems = [
    {
      title: "Home",
      url: "/",
      cName: "nav-links",
      icon: "fa-solid fa-house-user"
    },
    {
      title: "About",
      url: "/about",
      cName: "nav-links",
      icon: "fa-solid fa-info"
    },
    {
      title: "Generator",
      url: "/Generator",
      cName: "nav-links",
      icon: "fa-solid fa-brain"
    },
    {
      title: "Contract",
      url: "https://etherscan.io/token/0x3244a1b1506dabba5ecd85aaf08b909b8ed5bcf1",
      cName: "nav-links",
      icon: "fa-brands fa-ethereum",
      
    },
    {
      title: "White Paper",
      url: "/Whitepaper",
      cName: "nav-links",
      icon: "fa-solid fa-paperclip",
      
    },
    {
      title: "Buy Now",
      url: "https://www.pinksale.finance",
      cName: "nav-links",
      icon: "fa-solid fa-dollar-sign"
    }
  ];

  const mobileMenuItems = [
    {
      title: "Home",
      url: "/",
      cName: "nav-links",
      icon: "fa-solid fa-house-user"
    },
    {
      title: "About",
      url: "/about",
      cName: "nav-links",
      icon: "fa-solid fa-info"
    },
    {
      title: "Generator",
      url: "/Generator",
      cName: "nav-links",
      icon: "fa-solid fa-brain"
    },
    {
      title: "Contract",
      url: "https://etherscan.io/token/0x3244a1b1506dabba5ecd85aaf08b909b8ed5bcf1",
      cName: "nav-links",
      icon: "fa-brands fa-ethereum",
      
    },
    {
      title: "White Paper",
      url: "/Whitepaper",
      cName: "nav-links",
      icon: "fa-solid fa-paperclip",
      
    },
    {
      title: "Buy Now",
      url: "https://www.pinksale.finance",
      cName: "nav-links",
      icon: "fa-solid fa-dollar-sign"
    }
  ];

  // Use window.innerWidth to determine screen width
  const isMobile = window.innerWidth <= 844; // Adjust the breakpoint as needed

  const menuItems = isMobile ? mobileMenuItems : desktopMenuItems;

  return (
    <nav className="NavbarItems obj-width">
      <div />
      <h1>
        <Link className="navbar-logo" to={"/"}>
          Philosoraptor
        </Link>
      </h1>
      <div className="menu-icons" onClick={handleClick}>
        <i className={clicked ? "fas fa-times" : "fas fa-bars"}></i>
      </div>

      <ul className={clicked ? "nav-menu active" : "nav-menu"}>
        {menuItems.map((item, index) => {
          return (
            <li key={index}>
              <Link className={item.cName} to={item.url}>
                <i className={item.icon}></i>
                {item.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}

export default Navbar;


