import "./RoadmapStyles.css";

function TripData(props) {
  return (
    <div className="t-card">
      {/* <div className="t-image">
        <img src={props.image} alt="trip" />
      </div> */}
      <h4>{props.heading}</h4>
      <p>{props.text}</p>
      <p>{props.text2}</p>
      <p>{props.text3}</p>
      <p>{props.text4}</p>
      <p>{props.text5}</p>
    </div>
  );
}

export default TripData;
