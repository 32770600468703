import Navbar from "../components/Navbar";
import HomeHero from "../components/Home";
import Whitepaper1 from "../components/Whitepaper1";
import Footer from "../components/Footer";
import whitepaperImg from "../assets/raptorwhitepaper.webp";


function Whitepaper() {
  return (
    <>
      <Navbar />
      <HomeHero
        cName="hero-mid"
        heroImg={whitepaperImg}
        title="Whitepaper"
        btnClass="hide"
      />
      <Whitepaper1 />
      <Footer />
    </>
  );
}

export default Whitepaper;
