import React from "react";
import "./styles.css";
import Home from "./routes/Home";
import About from "./routes/About";
import Generator from "./routes/Generator";


import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
// Scroll to the top function

import { Route, Routes, useLocation } from "react-router-dom";
import { useLayoutEffect } from "react";
import Whitepaper from "./routes/Whitepaper";

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

function App() {
  return (
    <div className="App">
      <Wrapper>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/Generator" element={<Generator />} />
          
          <Route path="/Whitepaper" element={<Whitepaper />} />
          
        </Routes>
      </Wrapper>
    </div>
  );
}

export default App;
