import "./RoadmapStyles.css";
import TripData from "./RoadmapData";


function Roadmap() {
  return (
    
    <div className="trip obj-width"  >
      
      
      <h1>Roadmap</h1>
      <p></p>
      <div className="tripcard">
        <TripData
          heading=" Phase 1"
          text="♦ Presale"
          text2="♦ Launch"
          text3="♦ CoinGecko/Coinmarketcap Listings"
          text4="♦ 1000+ Holders"
          text5="♦ Use meme generator to get $RAPTOR Trending on Twitter"

        />
        <TripData
          
          heading="Phase 2"
          text="♦ Staking"
          text2="♦ Community Partnerships"
          text3="♦ 10,000+ Holders"
          text4="♦ CEX Listings"
          text5="♦ Philosoraptor Merch"
          
        />
        <TripData
          
          heading="Phase 3"
          text="♦ 100,000+ Holders"
          text2="♦ T1 Exchange Listings"
          text3="♦ $Raptor as payable currency for merch"
          text4="♦ Upgrade meme generator to AI meme generator"
        />
      
      </div>
    </div>
    
  );
}

export default Roadmap;
