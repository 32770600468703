import React, { Fragment  } from "react";
import Navbar from "../components/Navbar";
import HomeHero from "../components/Home";
import Tokenomics from "../components/Tokenomics";
import Footer from "../components/Footer";
import Roadmap from "../components/Roadmap";
import i1 from "../assets/raptor1.webp";

function Home() {
  
  return (
    <>
      <Navbar />
      <HomeHero
        cName="hero-mid"
        heroImg={i1}
        
        
        url="/"
      />
      <Tokenomics/>
      <Roadmap />
      <Footer />
    </>
  );
}

export default Home;
