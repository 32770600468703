import React from "react";
import "./HomeStyles.css";
import i1 from "../assets/twitter.webp";
//import i2 from "../assets/uniswap.webp";
//import i3 from "../assets/cmc.webp";
//import i4 from "../assets/dextools.webp";
import i5 from "../assets/etherscan.webp";
import i6 from "../assets/telegram.webp";
import i7 from "../assets/discord.webp";
// import i8 from "../assets/coingecko.webp";
// import i9 from "../assets/uniswaplong.webp";
// import i10 from "../assets/coinmarketcap.webp";
import i11 from "../assets/pinksale.webp";
import i12 from "../assets/splogo.webp";
import i13 from "../assets/dexview.webp";




function Home(props) {
  return (
    <>
      <div className={props.cName} style={{backgroundColor: "black"}}>
        
        <img src={props.heroImg} alt="heroImg" />
        <div className="hero-text">
          <h1 >$RAPTOR</h1>
          
          <p>Questioning the "most memeable" memecoins.</p>
          
            <a href="https://twitter.com/PhilosoRaptr" rel="noreferrer" title="Twitter" target="_blank" >
            <img src={i1} alt="Twitter" style={{width: 50, height: 60, paddingBottom: 5, paddingTop: 5}} />
            </a>
            &nbsp;
            &nbsp;
            <a href="https://discord.gg/8tYvkahCTM" rel="noreferrer" title="Discord" target="_blank">
            <img src={i7}  alt="Discord" style={{width: 50, height: 60, paddingBottom: 5, paddingTop: 5}} />
            </a>
            &nbsp;
            &nbsp;
            <a href="https://t.me/PhilosoraptorCoinOfficial" rel="noreferrer" title="Telegram" target="_blank">
            <img src={i6}  alt="Telegram" style={{width: 50, height: 60, paddingBottom: 5, paddingTop: 5}} />
            </a>
            &nbsp;
            &nbsp;
            <a href="https://etherscan.io/token/0x3244a1b1506dabba5ecd85aaf08b909b8ed5bcf1" rel="noreferrer" title="Etherscan" target="_blank">
            <img src={i5}  alt="Etherscan" style={{width: 50, height: 60, paddingBottom: 5, paddingTop: 5}} />
            </a>
            
            &nbsp;
            &nbsp;
            {/* <a href="https://www.dextools.io" rel="noreferrer" title="Dextools" target="_blank">
            <img src={i4}  alt="Dextools" style={{width: 50, height: 60, paddingBottom: 5, paddingTop: 5}} />
            </a>
            
            &nbsp;
            &nbsp;
            <a href="https://app.uniswap.org/#/swap" rel="noreferrer" title="Uniswap" target="_blank">
            <img src={i2}  alt="Uniswap" style={{width: 50, height: 60, paddingBottom: 5, paddingTop: 5}}  />
            </a> 
            &nbsp;
            &nbsp;  */}
            <a href="https://www.dexview.com" rel="noreferrer" title="Dexview" target="_blank">
            <img src={i13}  alt="Dexview" style={{width: 50, height: 60, paddingBottom: 5, paddingTop: 5}} />
            </a>  
            &nbsp;
            &nbsp;
            <a href="https://www.pinksale.finance/launchpad/0x98FAB4EDB017bC44C801D571ef37b78EA6589dCD?chain=ETH&refId=0xFD26fE9EcAe8AAE66d3254657e59246614f5e169" rel="noreferrer" title="Pinksale" target="_blank">
            <img src={i11}  alt="Pinksale" style={{width: 50, height: 60, paddingBottom: 5, paddingTop: 5}} />
            </a>  
            &nbsp;
            &nbsp;
            
            <a href="https://app.solidproof.io/projects/philosoraptor?audit_id=674" rel="noreferrer" title="Audit" target="_blank">
            <img src={i12}  alt="Audit" style={{width: 201, height: 60, paddingBottom: 5, paddingTop: 5}} />
            </a>             
        </div>  
      </div>
    </>
  );
}

export default Home;
