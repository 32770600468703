import "../components/AboutUsStyles.css";

function AboutUs() {
  return (
    <div className="about-container obj-width">
      <h1>About</h1>
      <p>
      Philosoraptor emerges from the shadows, poised to take its rightful place on the memecoin stage, contemplating whether the dogs and the frogs have truly monopolized the realm of memeable memecoins. As the ultimate embodiment of intellectual inquiry and wit, Philosoraptor brings a fresh perspective to the meme coin landscape. With each furrowed brow and quizzical gaze, $RAPTOR reminds us that while the dogs and frogs have their charm, the venerable raptor holds the key to unlocking a new realm of memetic brilliance.

Beyond the frenzy of fleeting trends, Philosoraptor stands as a beacon of contemplation and insight, urging us to delve deeper into the nuances of the meme universe. With the assurance of liquidity pool token incineration, relinquishment of contract control and a very modest tax, $RAPTOR stands as a coin for the masses, unswayed by the capricious tides of fleeting fame.

Guided by the enduring power of memetic influence, let Philosoraptor's reign as the king of memecoins serve as a reminder that true memeability transcends the whims of the moment. In the quest for a coin that embodies intellectual humor and timeless relevance, look no further than $RAPTOR to inspire a new era of memecoin greatness.
      </p>
    </div>
  );
}

export default AboutUs;
