import "./FooterStyles.css";
import i1 from "../assets/twitter.webp";
import i6 from "../assets/telegram.webp";
import i7 from "../assets/discord.webp";

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="obj-width">
          <div className="top">
            <div>
              <h1>Contact</h1>
              
            </div>
            
            
          </div>

          <div className="bottom">
           <div className="bottom-icon">
           <a href="https://twitter.com/PhilosoRaptr" rel="noreferrer" title="Twitter" target="_blank">
            <img src={i1} alt="Twitter" style={{width: 50, height: 50}} />
            </a>
            &nbsp;
            &nbsp;
            <a href="https://discord.gg/8tYvkahCTM" rel="noreferrer" title="Discord" target="_blank">
            <img src={i7}  alt="Discord" style={{width: 50, height: 50}} />
            </a>
            &nbsp;
            &nbsp;
            <a href="https://t.me/PhilosoraptorCoinOfficial" rel="noreferrer" title="Telegram" target="_blank">
            <img src={i6}  alt="Telegram" style={{width: 50, height: 50}} />
            </a>
            
            {/* &nbsp;
            <a href="https://www.discord.gg">
            <img src={i3}  style={{width: 80}} />
            </a> */}
            
            </div>
            <div>
            </div>
          </div>
          <div className="bottom">
            <p>Disclaimer: $Raptor Coin stands as an independent project designed to pay tribute to the "Philosoraptor" meme, maintaining no affiliation with Sam Smith or the original creation. This token possesses no inherent value and should not be interpreted as an investment opportunity, with no assurances of financial gain. Furthermore, $Raptor Coin operates without a formally established development team and primarily serves the purpose of entertainment and meme homage. It's important for those considering engagement to recognize its status as a non-legitimate financial instrument, and this project does not dispense financial advice. Prudence, diligent research, and a skeptical approach are recommended when navigating the cryptocurrency landscape, especially in relation to initiatives lacking transparency or substantial development progress.</p>
            
            
          </div>
          
        </div>
        
      </div>
      <p>© 2023 by Philosoraptor. All rights reserved!</p>
    </>
  );
};

export default Footer;
